import React, { useEffect, useState } from "react";
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { useSearchParams } from "react-router-dom";
import { Row, Col, Box, Spacer } from '../components/FlexBox'
import Card from "../components/Card"
import Pagination from "../components/Pagination"
import axios from "axios";

export default function Search() {
  const isSp = useMediaQuery(mediaQuery.sp)
  const [searchParams] = useSearchParams();
  const words = searchParams.get('words');

  const [posts, setPosts] = useState([])
  const fetchPosts = () => {
    const search = words.replace(" ", "+")
    axios
      .get(`https://iseshi-map.com/wp/wp-json/wp/v2/posts?search=${search}&orderby=date&per_page=17&page=1`)
      .then((res) => {
        setPosts(res.data)
      })
      .catch((res) => {
        console.log(res)
      })
  }

  let rows = []
  for(let i = 0; i < 3; i++) {
    let row = []
    for(let j = 0; j < 4; j++) {
      // const index = (i * 4) + j + (page == 1 ? 5 : 0)
      const index = (i * 4) + j
      if(posts.length > index) {
        row = [...row, posts[index]]
      }
    }
    if(row.length > 0) {
      rows = [...rows, row]
    }
  }

  useEffect(() => {
    fetchPosts()
  }, [words])

  return (
    <div className="is-content-list">
      <h1 className="is-title-pop">{words}の検索結果</h1>
      <Spacer height={isSp ? '41px' : '91px'} />
      {
        isSp ? (
          <Col spacing='15px' children={[
            posts.map((post, index) => {
              return <>
                {index > 0 && <div className="is-dashed" />}
                <Card post={post} style={{width: '100%'}} />
              </>
            })
          ]} />
        ) : (
          <Col spacing='15px' style={{ width: '100%' }} children={[
            rows.map((row, index) => {
              return (
                <Row align='start' spacing='2.2vw' style={{ width: '100%' }} children={[
                  row.map((post) => {
                    return <Col style={{width: '20.35vw'}} spacing="15px" children={[
                      index > 0 && <div className="is-dashed" />,
                      <Card post={post} style={{width: '100%'}} />
                    ]} />
                  }),
                  row.length < 4 && <Spacer />
                ]} />
              )
            })
          ]} />
        )
      }
      {/* 
      <Spacer height={isSp ? '99px' : '189px'} />
      <Box style={{
        width: '100%'
      }} children={[
        <Pagination pages={[1,2,3,4]} current={1} onClick={(page) => {
          console.log(page + "clicked.")
        }}/>
      ]} /> */}
    </div>
  );
}