import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Box, Spacer } from './FlexBox'
import { mediaQuery, useMediaQuery } from "../useMediaQuery";
import "./Card.css"

export default function Card({post, style}) {
  const isSp = useMediaQuery(mediaQuery.sp)
  const navigate = useNavigate()

  const title = post?.title.rendered ?? ""
  const dateComp = post?.date.split("T") ?? []
  const date = dateComp.length > 0 ? dateComp[0] : ""
  const excerpt = post?.excerpt?.rendered.slice(3).slice(0, -5) ?? ""
  const featured_url = post?.featured_url ?? "/images/noimage.jpeg"
  const featured = featured_url == false ? "/images/noimage.jpeg" : featured_url
  const color = post?.category_info?.color ?? "#666"

  const toEssay = () => {
    const slug = post?.slug
    slug && navigate(`/essay/${slug}`)
  }

  return (
    isSp ? (
      <Col align='start' style={style} children={[
        <img className="is-img clickable" src={featured} alt={title} style={{ 
          border: `1px solid ${color}` 
        }} onClick={toEssay} />,
        <Spacer height="9px" />,
        <p className="is-card-date">{date}</p>,
        <Spacer height="4px" />,
        <h3 className="is-card-title clickable" onClick={toEssay}>{title}</h3>,
        <Spacer height="4px" />,
        <p className="is-card-desc">{excerpt}</p>
      ]} />
    ) : (
      <Col align='start' style={style} children={[
        <img className="is-img clickable" src={featured} alt={title} style={{ 
          border: `1px solid ${color}` 
        }} onClick={toEssay} />,
        <Spacer height="7px" />,
        <Row align='start' style={{ width: '100%' }} children={[
          <h3 className="is-card-title clickable" onClick={toEssay}>{title}</h3>,
          <Spacer />,
          <Spacer width="4px" />,
          <p className="is-card-date" style={{marginTop: "2px"}}>{date}</p>
        ]} />,
        <Spacer height="13px" />,
        <p className="is-card-desc">{excerpt}</p>
      ]} />
    )
  )
}

export function RowCard({post, style}) {
  const isSp = useMediaQuery(mediaQuery.sp)
  const navigate = useNavigate()

  const title = post?.title.rendered ?? ""
  const dateComp = post?.date.split("T") ?? []
  const date = dateComp.length > 0 ? dateComp[0] : ""
  const excerpt = post?.excerpt?.rendered.slice(3).slice(0, -5) ?? ""
  const featured_url = post?.featured_url ?? "/images/noimage.jpeg"
  const featured = featured_url == false ? "/images/noimage.jpeg" : featured_url
  const color = post?.category_info?.color ?? "#666"

  const toEssay = () => {
    const slug = post?.slug
    slug && navigate(`/essay/${slug}`)
  }

  return (
    isSp ? (
      <Col align='start' spacing="9px" style={style} children={[
        <img className="is-img clickable" src={featured} alt={title} style={{ 
          border: `1px solid ${color}` 
        }} onClick={toEssay} />,
        <h3 className="is-card-title row clickable" onClick={toEssay}>{title}</h3>,
        <p className="is-card-desc row">{excerpt}</p>
      ]} />
    ) : (
      <Col align='start' style={style} children={[
        <img className="is-img clickable" src={featured} alt={title} style={{ 
          border: `1px solid ${color}` 
        }} onClick={toEssay} />,
        <Spacer height="7px" />,
        <Row align='start' style={{ width: '100%' }} children={[
          <h3 className="is-card-title clickable" onClick={toEssay}>{title}</h3>,
          <Spacer />,
          <Spacer width="4px" />,
          <p className="is-card-date" style={{marginTop: "2px"}}>{date}</p>
        ]} />,
        <Spacer height="13px" />,
        <p className="is-card-desc">{excerpt}</p>
      ]} />
    )
  )
}

export function TopCard({post, style, isNew}) {
  const isSp = useMediaQuery(mediaQuery.sp)
  const navigate = useNavigate()

  const title = post?.title.rendered ?? ""
  const dateComp = post?.date.split("T") ?? []
  const date = dateComp.length > 0 ? dateComp[0] : ""
  const excerpt = post?.excerpt?.rendered.slice(3).slice(0, -5) ?? ""
  const featuredUrl = post?.featured_url ?? "/images/noimage.jpeg"
  const featured = featuredUrl == false ? "/images/noimage.jpeg" : featuredUrl
  const color = post?.category_info?.color ?? "#666"

  const toEssay = () => {
    const slug = post?.slug
    slug && navigate(`/essay/${slug}`)
  }

  return (
    isSp ? (
      <Col align='start' style={style} children={[
        <Box verticalAlign="end" horizontalAlign="start" style={{width: "100%"}} children={[
          <img className="is-img clickable" src={featured} alt={title} style={{ 
            border: `1px solid ${color}` 
          }} onClick={toEssay} />,
          isNew && <div className="is-card-new">新着だよ！</div>
        ]} />,
        <Spacer height="9px" />,
        <p className="is-card-date top">{date}</p>,
        <Spacer height="4px" />,
        <h3 className="is-card-title top clickable" onClick={toEssay}>{title}</h3>,
        <Spacer height="5px" />,
        <p className="is-card-desc bold top">{excerpt}</p>
      ]} />
    ) : (
      <Col align='start' style={style} children={[
        <Box verticalAlign="end" horizontalAlign="start" style={{width: "100%"}} children={[
          <img className="is-img clickable" src={featured} alt={title} style={{ 
            border: `1px solid ${color}` 
          }} onClick={toEssay} />,
          isNew && <div className="is-card-new">新着だよ！</div>
        ]} />,
        <Spacer height="17px" />,
        <Row align='start' style={{ width: '100%' }} children={[
          <h3 className="is-card-title top clickable" style={{
            marginTop: "51px"
          }} onClick={toEssay}>{title}</h3>,
          <Spacer />,
          <Spacer width="4px" />,
          <p className="is-card-date top">{date}</p>
        ]} />,
        <Spacer height="26px" />,
        <p className="is-card-desc bold top">{excerpt}</p>
      ]} />
    )
  )
}