import React, { useEffect, useState } from "react"
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { useNavigate } from "react-router-dom"
import axios from "axios"
import Leaflet from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from 'react-leaflet'
import "./Maps.css"

function CurrentMarker({position}) {
  const icon = Leaflet.divIcon({
    html: `
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
        <circle cx="18" cy="18" r="18" fill="#ffffff" />
        <circle cx="18" cy="18" r="12" fill="#38A7D6" />
      </svg>`,
    iconSize: 0,
    iconAnchor: [18, 18]
  })
  return <Marker position={position} icon={icon} />
}

function PointingMarker({position}) {
  const isSp = useMediaQuery(mediaQuery.sp)
  const size = isSp ? 44 : 66
  const icon = Leaflet.divIcon({
    html: `<img src="/images/pin_focused.svg" width="${size}" height="${size}" alt="focused position" />`,
    iconSize: 0,
    iconAnchor: [0, size]
  })
  return <Marker position={position} icon={icon} />
}

function PostPin({post, mag, onClick}) {
  const isSp = useMediaQuery(mediaQuery.sp)
  const map = useMap()
  const latlng = post.acf?.latlng
  if(latlng == undefined) { return }
  const position = [latlng.lat, latlng.lng]
  const featured = post.featured_url ?? "/images/noimage.jpeg"
  const color = post.category_info.color ?? "#666"
  const width = (isSp ? 48 : 73) * (mag ?? 1)
  const height = (isSp ? 76 : 108) * (mag ?? 1)

  const icon = Leaflet.divIcon({
    html: `
      <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 77 119" fill="none">
        <path d="M45.9744 82.2249L46.3043 80.7954L44.8532 81.0108C40.727 81.6234 36.273 81.6234 32.1468 81.0108L30.6917 80.7948L31.0262 82.2274L37.5647 110.227L38.5443 114.422L39.5129 110.225L45.9744 82.2249Z" fill="${color}" stroke="white" stroke-width="2" stroke-miterlimit="5.75877"/>
        <path d="M38.5 76C59.2107 76 76 59.2107 76 38.5C76 17.7893 59.2107 1 38.5 1C17.7893 1 1 17.7893 1 38.5C1 59.2107 17.7893 76 38.5 76Z" fill="${color}" stroke="white" stroke-width="2" />
        <clipPath id="clip" transform="">
          <circle cx="38.5" cy="38.5" r="33.5" />
        </clipPath>
        <image x="-35" y="0" height="77" xlink:href="${featured}" clip-path="url(#clip)"/>
      </svg>`,
    icon: undefined,
    iconSize: 0,
    iconAnchor: [width / 2, height]
  })

  return (
    <Marker position={position} icon={icon} eventHandlers={{
      click() {
        map.panTo(latlng)
        onClick()
      }
    }} />
  )
}

function GetMapEvents({onClick, onChangeZoom, onChangeAround}) {
  const map = useMap()
  const mapEvents = useMapEvents({
    dblclick: (event) => {
      const latlng = event.latlng
      map.panTo(latlng)
      onClick(latlng)
    },
    zoomend: () => {
      onChangeZoom(mapEvents.getZoom())
    },
  })
  return null
}

export function MainMap({onChangeSelection, onChangeAround}) {
  const isSp = useMediaQuery(mediaQuery.sp)
  const initZoom = 14
  const initCenter = [34.49111, 136.709500] // 伊勢市駅
  const initBounds = Leaflet.latLngBounds(
    Leaflet.latLng(50.56928286558243, 149.89746093750003),
    Leaflet.latLng(17.09879223767869, 126.29882812500001),
  ) // 操作可能範囲

  /** マップ上のインタラクション */
  const [zoom, setZoom] = useState(initZoom)
  const [pointing, setPointing] = useState(undefined) // 選択した位置
  const [selection, setSelection] = useState(undefined) // 選択したピン

  /** 起動時に全投稿を取得する */
  const [posts, setPosts] = useState([])
  useEffect(() => {
    axios
      .get(`https://iseshi-map.com/wp/wp-json/wp/v2/posts?_fields=acf,featured_url,category_info,title,date,excerpt,slug&per_page=100`)
      .then((res) => {
        const posts = res.data
        setPosts(posts)
      })
      .catch((res) => {
        console.log(res)
      })
  }, [])

  /** 起動時に現在地を取得する */
  const [current, setCurrent] = useState(undefined) // 現在地
  useEffect(() => {
    const watching = navigator.geolocation.watchPosition(position => {
      const { latitude, longitude } = position.coords
      setCurrent([latitude, longitude])
    })
    return () => {
      navigator.geolocation.clearWatch(watching)
    }
  }, [])

  /** 表示するピンを限定する */
  const displays = posts.filter((post) => {
    const priority = parseInt(post.acf?.priority) ?? 5
    return priority == 1 || (priority + 11) < zoom
  })

  /** 周辺のピンを返す */
  useEffect(() => {
    const nearBy = pointing ?? current
    if (displays && displays.length > 0 && nearBy != undefined) {
      const cx = nearBy[0]
      const cy = nearBy[1]
      const around = displays.sort((a, b) => {
        const ax = a.acf?.latlng?.lat ?? 0
        const ay = a.acf?.latlng?.lng ?? 0
        const bx = b.acf?.latlng?.lat ?? 0
        const by = b.acf?.latlng?.lng ?? 0
        const disA = Math.sqrt((cx-ax)*(cx-ax)+(cy-ay)*(cy-ay))
        const disB = Math.sqrt((cx-bx)*(cx-bx)+(cy-by)*(cy-by))
        return disA - disB
      }).slice(0, isSp ? 3 : 4)
      onChangeAround(around)
    } else {
      // 最初は取得した順に表示する
      const around = displays.slice(0, isSp ? 3 : 4)
      onChangeAround(around)
    }
  }, [zoom, current, pointing, posts])

  return (
    <div className="is-map-main">
      <MapContainer
        center={initCenter} 
        maxBounds={initBounds} 
        zoom={initZoom} 
        zoomControl={false} 
        doubleClickZoom={false}
      >
        <GetMapEvents onClick={(latlng) => {
          setPointing([latlng.lat, latlng.lng])
          setSelection(undefined)
          onChangeSelection(undefined)
        }} onChangeZoom={setZoom}/>
        <TileLayer attribution="<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a>" url='https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'/>
        {
          displays.map((post) => {
            return <PostPin post={post} mag={
              selection == post ? (isSp ? 1.2 : 2.5) : 1
            } onClick={() => { 
              setPointing(undefined)
              setSelection(post)
              onChangeSelection(post)
            }} />
          })
        }
        { current && <CurrentMarker position={current} /> }
        { pointing && <PointingMarker position={pointing} /> }
      </MapContainer>
    </div>
  )
}

export function AroundMap({onChangeAround}) {
  const navigate = useNavigate()
  const initZoom = 14
  const initCenter = [34.49111, 136.709500] // 伊勢市駅
  const initBounds = Leaflet.latLngBounds(
    Leaflet.latLng(50.56928286558243, 149.89746093750003),
    Leaflet.latLng(17.09879223767869, 126.29882812500001),
  ) // 操作可能範囲

  /** マップ上のインタラクション */
  const [zoom, setZoom] = useState(initZoom)
  const [pointing, setPointing] = useState(undefined) // 選択した位置

  /** 起動時に全投稿を取得する */
  const [posts, setPosts] = useState([])
  useEffect(() => {
    axios
      .get(`https://iseshi-map.com/wp/wp-json/wp/v2/posts?_fields=acf,featured_url,category_info,title,date,excerpt,slug&per_page=100`)
      .then((res) => {
        const posts = res.data
        setPosts(posts) // 最初は取得した順に表示する
      })
      .catch((res) => {
        console.log(res)
      })
  }, [])

  /** 起動時に現在地を取得する */
  const [current, setCurrent] = useState(undefined) // 現在地
  useEffect(() => {
    const watching = navigator.geolocation.watchPosition(position => {
      const { latitude, longitude } = position.coords
      setCurrent([latitude, longitude])
    })
    return () => {
      navigator.geolocation.clearWatch(watching)
    }
  }, [])

  /** 表示するピンを限定する */
  const displays = posts.filter((post) => {
    const priority = parseInt(post.acf?.priority) ?? 5
    return priority == 1 || (priority + 11) < zoom
  })

  /** 周辺のピンを返す */
  useEffect(() => {
    if (displays && displays.length > 0) {
      const nearBy = pointing ?? current
      if (nearBy == undefined) { return }
      const cx = nearBy[0]
      const cy = nearBy[1]
      const around = displays.sort((a, b) => {
        const ax = a.acf?.latlng?.lat ?? 0
        const ay = a.acf?.latlng?.lng ?? 0
        const bx = b.acf?.latlng?.lat ?? 0
        const by = b.acf?.latlng?.lng ?? 0
        const disA = Math.sqrt((cx-ax)*(cx-ax)+(cy-ay)*(cy-ay))
        const disB = Math.sqrt((cx-bx)*(cx-bx)+(cy-by)*(cy-by))
        return disA - disB
      }).slice(0, 4)
      onChangeAround(around)
    }
  }, [zoom, current, pointing])

  return (
    <div className="is-map-around">
      <MapContainer
        center={initCenter} 
        maxBounds={initBounds} 
        zoom={initZoom} 
        zoomControl={false} 
        doubleClickZoom={false}
      >
        <GetMapEvents onClick={(latlng) => {
          setPointing([latlng.lat, latlng.lng])
        }} onChangeZoom={setZoom}/>
        <TileLayer attribution="<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a>" url='https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'/>
        {
          displays.map((post) => {
            return <PostPin post={post} onClick={() => { 
              const slug = post.slug ?? ""
              slug && navigate(`/essay/${slug}`)
            }} />
          })
        }
        { current && <CurrentMarker position={current} /> }
        { pointing && <PointingMarker position={pointing} /> }
      </MapContainer>
    </div>
  )
}

export function PostMap({post}) {
  const isSp = useMediaQuery(mediaQuery.sp)
  const initZoom = 14
  const latlng = post?.acf?.latlng
  const initCenter = [latlng.lat, latlng.lng]
  const initBounds = Leaflet.latLngBounds(
    Leaflet.latLng(50.56928286558243, 149.89746093750003),
    Leaflet.latLng(17.09879223767869, 126.29882812500001),
  ) // 操作可能範囲

  /** 起動時に現在地を取得する */
  const [current, setCurrent] = useState(undefined) // 現在地
  useEffect(() => {
    const watching = navigator.geolocation.watchPosition(position => {
      const { latitude, longitude } = position.coords
      setCurrent([latitude, longitude])
    })
    return () => {
      navigator.geolocation.clearWatch(watching)
    }
  }, [])

  return (
    <div className="is-map-post">
      <MapContainer
        center={initCenter}
        maxBounds={initBounds}
        zoom={initZoom}
        zoomControl={false}
        doubleClickZoom={false}
      >
        <TileLayer attribution="<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a>" url='https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'/>
        <PostPin post={post} mag={1.2} onClick={() => {}} />
        { current && <CurrentMarker position={current} /> }
      </MapContainer>
    </div>
  )
}