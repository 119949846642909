import React from "react";
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { Row, Col, Box, Spacer } from './FlexBox'
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";

function Line() {
  const isSp = useMediaQuery(mediaQuery.sp)

  return (
    isSp ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 287 2" fill="none">
      <path d="M0 1H287" stroke="white" strokeDasharray="2 2"/>
    </svg>
    : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 825 2" fill="none">
      <path d="M0.817383 1.17871H824.907" stroke="white" strokeDasharray="2 2"/>
    </svg>
  )
}

function Commentary() {
  const isSp = useMediaQuery(mediaQuery.sp)
  const navigate = useNavigate()

  return (
    <Col align={isSp ? 'center' : 'start'} spacing='16px' style={{
      width: isSp ? '100%' : '30vw'
    }} children={[
      <img className="clickable" src="/images/title_logo.svg" alt="イセシマップ" onClick={() => {
        navigate('/')
      }} />,
      <p className="is-footer-desc">
        伊勢志摩って地域は『伊勢神宮』があるからか、結構日本の歴史や文化の根源だったりするエピソードを発見をすることがあるんですよ。<br />
        エピソードを知っているだけで、『<strong>何でもない場所</strong>』があなたにだけは『<strong>魅力的な目的地</strong>』になるかもしれませんね。
      </p>
    ]} />
  )
}

function SNSIcons() {
  const isSp = useMediaQuery(mediaQuery.sp)

  return (
    <Row spacing="25px" style={{
      width: isSp ? '100%' : 'auto'
    }} children={[
      isSp && <Spacer />,
      <img className="is-sns-icon" src='/images/instagram_icon_white.svg' alt='instagram' onClick={() => {
        window.open("https://www.instagram.com/iseshi_map/?hl=ja")
      }} />,
      isSp && <Spacer />,
      <img className="is-sns-icon" src='/images/youtube_icon_white.svg' alt='youtube' onClick={() => {
        window.open("https://www.youtube.com/@OPENRADIO-yd6zt")
      }} />,
      isSp && <Spacer />,
      <img className="is-sns-icon" src='/images/note_icon_white.svg' alt='note' onClick={() => {
        window.open("https://note.com/cronnaua")
      }} />,
      isSp && <Spacer />,
    ]} />
  )
}

function SiteMap() {
  const isSp = useMediaQuery(mediaQuery.sp)
  const navigate = useNavigate()

  return (
    isSp ? <Col align='start' spacing='16.97px' style={{
      width: '100%'
    }} children={[
      // <p className="is-footer-sitemap" onClick={() => {
      //   navigate('/contact')
      // }}>取材依頼について</p>,
      <p className="is-footer-sitemap" onClick={() => {
        navigate('/privacy-policy')
      }}>プライバシーポリシー</p>,
    ]} />
    : <Col align='end' style={{ marginTop: '6px' }} children={[
      <Row spacing='1.46vw' children={[
        // <p className="is-footer-sitemap" onClick={() => {
        //   navigate('/contact')
        // }}>取材依頼について</p>,
        <p className="is-footer-sitemap" onClick={() => {
          navigate('/privacy-policy')
        }}>プライバシーポリシー</p>,
      ]} />,
    ]} />
  )
}

function Copyright() {
  const isSp = useMediaQuery(mediaQuery.sp)

  return (
    <Row style={{
      width: '100%'
    }} children={[
      !isSp && <Spacer />,
      <p className="is-footer-copyright">© 2024 <span className="is-footer-copyright-logo">イセシマップ</span></p>,
    ]} />
  )
}

export default function Footer() {
  const isSp = useMediaQuery(mediaQuery.sp)
  const navigate = useNavigate()

  return (
    <footer className="is-footer">{
      isSp ? <Col style={{
        width: '90vw',
        margin: '0 auto',
      }} children={[
        <Spacer height='36px' />,
        <SNSIcons />,
        <Spacer height='30px' />,
        <Line />,
        <Spacer height='28px' />,
        <Commentary />,
        <Spacer height='29px' />,
        <Line />,
        <Spacer height='29px' />,
        <SiteMap />,
        <Spacer height='52px' />,
        <Copyright />,
        <Spacer height='31px' />,
      ]} />
      : <Row style={{
        width: '90vw',
        height: '100%',
        margin: '0 auto',
      }} children={[
        <Commentary />,
        <Spacer />,
        <Col style={{
          width: '55vw'
        }} children={[
          <Row spacing='1.4vw' style={{
            width: '100%'
          }} children={[
            <p className="is-nav-item" onClick={() => {
              navigate('/')
            }}>HOME</p>,
            <p className="is-nav-item" onClick={() => {
              navigate('/about')
            }}>ABOUT</p>,
            <p className="is-nav-item" onClick={() => {
              navigate('/category/history')
            }}>History</p>,
            <p className="is-nav-item" onClick={() => {
              navigate('/category/foods')
            }}>Foods</p>,
            <p className="is-nav-item" onClick={() => {
              navigate('/category/character')
            }}>Character</p>,
            <p className="is-nav-item" onClick={() => {
              navigate('/category/others')
            }}>Others</p>,
            <Spacer />,
            <SearchBar />,
          ]} />,
          <Spacer height='50px' />,
          <Line />,
          <Spacer height='16px' />,
          <Row align='start' style={{
            width: '100%'
          }} children={[
            <SNSIcons />,
            <Spacer />,
            <SiteMap />,
          ]} />,
          <Spacer height='51.3px' />,
          <Copyright />,
        ]} />,
      ]} />
    }</footer>
  );
}