import React from 'react'

/**
 * - required children: [Component]
 * - horizontalAlign: 'start' or 'center' or 'end'
 * - verticalAlign: 'start' or 'center' or 'end'
 * - style: additional style
 * - onClick: event
 * - className: class
 */
export function Box(props) {
  return (
    <div style={props.style} onClick={props.onClick} className={props.className}>
      <div style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}>{props.children.map((child, index) => {
        return <div style={{
          width: '100%',
          height: '100%',
          position: index === 0 ? 'relative' : 'absolute',
          display: 'flex',
          justifyContent: props.horizontalAlign ?? 'center',
          alignItems: props.verticalAlign ?? 'center',
        }}>{child}</div>
      })}</div>
    </div>
  )
}

/**
 * - required children: [Component]
 * - align: 'start' or 'center' or 'end'
 * - style: additional style
 */
export function Col(props) {
  return (
    <div style={props.style} onClick={props.onClick} className={props.className}>
      <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: props.align ?? 'center',
        gap: props.spacing ?? 0,
        margin: 0,
        padding: 0,
      }}>{props.children}</div>
    </div>
  )
}

/**
 * - required children: [Component]
 * - align: 'start' or 'center' or 'end'
 * - style: additional style
 */
export function Row(props) {
  return (
    <div style={props.style} onClick={props.onClick} className={props.className}>
      <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: props.align ?? 'center',
        gap: props.spacing ?? 0,
        margin: 0,
        padding: 0,
      }}>{props.children}</div>
    </div>
  )
}

/**
 * - width: default flex
 * - height: default flex
 */
export function Spacer(props) {
  return (
    <div style={props.width == null && props.height == null ? {
      flex: 1,
      margin: 0,
      padding: 0,
    } : {
      width: props.width ?? 0,
      height: props.height ?? 0,
      margin: 0,
      padding: 0,
    }}/>
  )
}