import React, { useEffect, useState } from "react";
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Row, Col, Box, Spacer } from '../components/FlexBox'
import Card, { RowCard, TopCard } from "../components/Card"

function CategoryCardRow({category}) {
  const isSp = useMediaQuery(mediaQuery.sp)
  const navigate = useNavigate()

  const [posts, setPosts] = useState([])
  const fetchPosts = () => {
    axios
      .get(`https://iseshi-map.com/wp/wp-json/wp/v2/posts?per_page=4&page=1&orderby=date&categories=${category.id}`, {
        auth: { username: "aiu", password: "eo" }
      })
      .then((res) => {
        setPosts(res.data)
      })
      .catch((res) => {
        console.log(res)
      })
  }

  const name = category?.name ?? ""

  const toCategory = () => {
    const slug = category?.slug
    slug && navigate(`/category/${slug}`)
  }

  useEffect(() => {
    fetchPosts()
  }, [])

  return (
    <div style={{width: '100%'}}>
      <p className="is-subtitle-above">お伊勢さん</p>
      <h2 className="is-subtitle">{name} essay</h2>
      <Spacer height='22px' />
      {
        isSp ? (
          <div className="is-scroll-horizontal" style={{left: "-2.5vw"}}>
            <Row className="is-scroll-content" align='start' spacing='5.6vw' children={[
              posts.map((post) => {
                return <Card post={post} style={{width: '56vw'}} />
              })
            ]} />
          </div>
        ) : (
          <Row align='start' spacing='2.2vw' style={{ width: '100%' }} children={[
            posts.map((post) => {
              return <Card key={post} post={post} style={{width: '20.35vw'}} />
            }),
            posts.length < 4 && <Spacer key="-1" />
          ]} />
        )
      }
      <Spacer height='50px' />
      <Row style={{width: "100%"}} children={[
        <button className="is-button" onClick={toCategory}>More...</button>
      ]} />
    </div>
  )
}

export default function Home() {
  const isSp = useMediaQuery(mediaQuery.sp)

  const [posts, setPosts] = useState([])
  const fetchPosts = () => {
    axios
      .get(`https://iseshi-map.com/wp/wp-json/wp/v2/posts?per_page=5&page=1&orderby=date`, {
        auth: { username: "aiu", password: "eo" }
      })
      .then((res) => {
        setPosts(res.data)
      })
      .catch((res) => {
        console.log(res)
      })
  }

  const [categories, setCategories] = useState([])
  const fetchCategories = () => {
    axios
      .get("https://iseshi-map.com/wp/wp-json/wp/v2/categories", {
        auth: { username: "aiu", password: "eo" }
      })
      .then((res) => {
        const datas = res.data
          .filter((category) => { return category.acf?.index > 0  })
          .sort((a, b) => { return (a.acf?.index - b.acf?.index) ?? -1 })
        console.log(datas)
        setCategories(datas)
      })
      .catch((res) => {
        console.log(res)
      })
  }

  useEffect(() => {
    fetchPosts()
    fetchCategories()
  }, [])

  return (
    <div className="is-content-list">
      <h1 className="is-title">新着エッセイ</h1>
      <Spacer height={isSp ? '34px' : '62px'} />
      {
        posts.length > 0 && (
          isSp ? (
            <Col spacing='15px' children={
              posts.map((post, index) => {
                return index == 0 ? (
                  <TopCard key={post} isNew post={post} style={{width: '100%'}} />
                ) : (
                  <>
                    <div className="is-dashed" />
                    <Card key={post} post={post} style={{width: '100%'}} />
                  </>
                )
              })
            } />
          ) : (
            <Row align='start' children={[
              posts.length > 0 && <TopCard key="0" isNew post={posts[0]} style={{ width: '40.70vw' }} />,
              <Spacer key="-1" />,
              <Col key="r" spacing='42px' style={{ width: '42.9vw' }} children={[
                <Row key="rt" align='start' spacing='2.2vw' style={{ width: '100%' }} children={[
                  posts.length > 1 ? <Card key="1" post={posts[1]} style={{width: '20.35vw'}} /> : <Spacer key="1" />,
                  posts.length > 2 ? <Card key="2" post={posts[2]} style={{width: '20.35vw'}} /> : <Spacer key="2" />,
                ]} />,
                <Row key="rb" align='start' spacing='2.2vw' style={{ width: '100%' }} children={[
                  posts.length > 3 ? <Card key="3" post={posts[3]} style={{width: '20.35vw'}} /> : <Spacer key="3" />,
                  posts.length > 4 ? <Card key="4" post={posts[4]} style={{width: '20.35vw'}} /> : <Spacer key="4" />,
                ]} />,
              ]} />,
            ]} />
          ) 
        )
      }
      <Spacer height={isSp ? '89px' : '200px'} />
      {
        categories.length > 0 && (
          <Col spacing={isSp ? '103px' : '150px'} children={
            categories.map((category) => {
              return <CategoryCardRow key={category} category={category} />
            })
          } />
        )
      }
    </div>
  );
}