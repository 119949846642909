import React, { useState } from "react";
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { Row, Col, Box, Spacer } from './FlexBox'
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";

export default function Header() {
  const isSp = useMediaQuery(mediaQuery.sp)
  const navigate = useNavigate()
  const [isOver, setIsOver] = useState(false)
  
  return (
    <header className="is-header">{
      isSp ? <Row spacing='9px' style={{
        width: '93.4vw',
        height: '100%',
        marginTop: '13px',
        marginLeft: '2.5vw',
        marginRight: 'auto',
      }} children={[
        <SearchBar style={{ flex: 1 }} />,
        <img class="clickable" src='/images/menu_icon.svg' alt='menu' onClick={() => {
          setIsOver(true)
        }}/>
      ]} />
      : <Row spacing='1.4vw' style={{
        width: '92vw',
        height: '100%',
        marginLeft: '5.5vw',
        marginRight: 'auto',
      }} children={[
        <p className="is-nav-item" onClick={() => {
          navigate('/')
        }}>HOME</p>,
        <p className="is-nav-item" onClick={() => {
          navigate('/about')
        }}>ABOUT</p>,
        <p className="is-nav-item" onClick={() => {
          navigate('/category/history')
        }}>History</p>,
        <p className="is-nav-item" onClick={() => {
          navigate('/category/foods')
        }}>Foods</p>,
        <p className="is-nav-item" onClick={() => {
          navigate('/category/character')
        }}>Character</p>,
        <p className="is-nav-item" onClick={() => {
          navigate('/category/others')
        }}>Others</p>,
        <Spacer />,
        <SearchBar />,
        <img className="clickable" src="/images/title_logo.svg" alt="イセシマップ" onClick={() => {
          navigate('/')
        }} />,
      ]} />
    }{
      isSp && isOver && <Col spacing='10.5vh' style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        background: 'rgba(0, 128, 146, 0.50)',
      }} onClick={() => {
        setIsOver(false)
      }} children={[
        <Col spacing='3.3vh' children={[
          <p className="is-nav-item" onClick={() => {
            navigate('/')
            setIsOver(false)
          }}>HOME</p>,
          <p className="is-nav-item" onClick={() => {
            navigate('/about')
            setIsOver(false)
          }}>ABOUT</p>,
          <p className="is-nav-item" onClick={() => {
            navigate('/category/history')
            setIsOver(false)
          }}>History</p>,
          <p className="is-nav-item" onClick={() => {
            navigate('/category/foods')
            setIsOver(false)
          }}>Foods</p>,
          <p className="is-nav-item" onClick={() => {
            navigate('/category/character')
            setIsOver(false)
          }}>Character</p>,
          <p className="is-nav-item" onClick={() => {
            navigate('/category/others')
            setIsOver(false)
          }}>Others</p>,
        ]} />,
        <svg className="clickable" xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none" onClick={() => {
          setIsOver(false)
        }}>
          <path d="M2 2L27 27" stroke="white" strokeWidth="3" strokeLinecap="round"/>
          <path d="M27 2L2 27" stroke="white" strokeWidth="3" strokeLinecap="round"/>
        </svg>,
      ]} />
    }</header>
  );
}