import React, { useEffect, useState } from "react";
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Row, Col, Box, Spacer } from '../components/FlexBox'
import Card from "../components/Card"
import Pagination from "../components/Pagination"

export default function Category() {
  const isSp = useMediaQuery(mediaQuery.sp)
  const params = useParams()
  // const [searchParams, setSearchParams] = useSearchParams()
  // const page = searchParams.get("page") ?? 1

  const [category, setCategory] = useState(undefined)
  const fetchCategory = () => {
    axios
      .get(`https://iseshi-map.com/wp/wp-json/wp/v2/categories?slug=${params.category}`)
      .then((res) => {
        if(res.data.length > 0) {
          const category = res.data.shift()
          setCategory(category)
          fetchPosts(category)
        }
      })
      .catch((res) => {
        console.log(res)
      })
  }

  const [posts, setPosts] = useState([])
  const fetchPosts = (category) => {
    
    const id = category.id
    // const count = isSp ? 7 : page == 1 ? 17 : 12
    // const offset = (isSp || page == 1) ? 0 : 5
    axios
    // .get(`https://iseshi-map.com/wp/wp-json/wp/v2/posts?categories=${id}&orderby=date&offset=${offset}&per_page=${count}&page=${page}`)
      .get(`https://iseshi-map.com/wp/wp-json/wp/v2/posts?categories=${id}&orderby=date&per_page=17&page=1`)
      .then((res) => {
        setPosts(res.data)
      })
      .catch((res) => {
        console.log(res)
      })
  }

  const name = category?.name ?? ""

  let rows = []
  for(let i = 0; i < 3; i++) {
    let row = []
    for(let j = 0; j < 4; j++) {
      // const index = (i * 4) + j + (page == 1 ? 5 : 0)
      const index = (i * 4) + j + 5
      if(posts.length > index) {
        row = [...row, posts[index]]
      }
    }
    if(row.length > 0) {
      rows = [...rows, row]
    }
  }

  useEffect(() => {
    fetchCategory()
  }, [category/*isSp, page*/])

  return (
    <div className="is-content-list">
      <h1 className="is-title">{name} essay</h1>
      <Spacer height={isSp ? '41px' : '91px'} />
      {
        isSp ? (
          <Col spacing='15px' children={
            posts.map((post, index) => {
              return <Card post={post} isLined={index > 0} style={{width: '100%'}} />
            })
          } />
        ) : (
          <Col spacing="68px" style={{ width: "100%" }} children={[
            // page == 1 && (
              <Row align='start' style={{ width: "100%" }} children={[
                posts.length > 0 && <Card type="large" post={posts[0]} style={{ width: '40.70vw' }} />,
                <Spacer />,
                <Col spacing='42px' style={{ width: '42.9vw' }} children={[
                  <Row align='start' spacing='2.2vw' style={{ width: '100%' }} children={[
                    posts.length > 1 ? <Card post={posts[1]} style={{width: '20.35vw'}} /> : <Spacer />,
                    posts.length > 2 ? <Card post={posts[2]} style={{width: '20.35vw'}} /> : <Spacer />,
                  ]} />,
                  <Row align='start' spacing='2.2vw' style={{ width: '100%' }} children={[
                    posts.length > 3 ? <Card post={posts[3]} style={{width: '20.35vw'}} /> : <Spacer />,
                    posts.length > 4 ? <Card post={posts[4]} style={{width: '20.35vw'}} /> : <Spacer />,
                  ]} />,
                ]} />,
              ]} />,
            // ),
            <Col spacing='15px' style={{ width: '100%' }} children={[
              rows.map((row) => {
                return (
                  <Row align='start' spacing='2.2vw' style={{ width: '100%' }} children={[
                    row.map((post) => {
                      return <Col style={{width: '20.35vw'}} spacing="15px" children={[
                        <div className="is-dashed" />,
                        <Card post={post} style={{width: '100%'}} />
                      ]} />
                    }),
                    row.length < 4 && <Spacer />
                  ]} />
                )
              })
            ]} />
          ]} />
        )
      }
      {/* <Spacer height={isSp ? '99px' : '189px'} /> */}
      {/* <Box style={{ width: '100%' }} children={[
        <Pagination pages={[1,2,3,4]} current={page} onClick={(page) => {
          setSearchParams({ page: page })
        }}/>
      ]} /> */}
    </div>
  );
}