import React, { useEffect, useState } from "react"
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { Row, Col, Box, Spacer } from '../components/FlexBox'
import { useNavigate, useParams } from "react-router-dom"
import parse from "html-react-parser"
import "./Post.css"
import axios from "axios"
import Leaflet from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from 'react-leaflet'
import { PostMap } from "../components/Maps"

export default function Post() {
  const isSp = useMediaQuery(mediaQuery.sp)
  const params = useParams()
  const bounds = Leaflet.latLngBounds(
    Leaflet.latLng(50.56928286558243, 149.89746093750003),
    Leaflet.latLng(17.09879223767869, 126.29882812500001),
  )

  /** 起動時に現在地を取得する */
  const [current, setCurrent] = useState(undefined) // 現在地
  const [center, setCenter] = useState(undefined)

  useEffect(() => {
    const watching = navigator.geolocation.watchPosition(position => {
      const { latitude, longitude } = position.coords
      setCurrent([latitude, longitude])
    })
    return () => {
      navigator.geolocation.clearWatch(watching)
    }
  }, [])

  const [post, setPost] = useState(undefined)
  const fetchPosts = () => {
    axios
      .get(`https://iseshi-map.com/wp/wp-json/wp/v2/posts?slug=${params.post}`)
      .then((res) => {
        const posts = res.data
        const post = posts.length > 0 && posts[0]
        post && setPost(post)
        const latlng = post.acf?.latlng
        latlng && setCenter([latlng.lat, latlng.lng])
      })
      .catch((res) => {
        console.log(res)
      })
  }
  
  const title = post?.title?.rendered ?? ""
  const dateComp = post?.date.split("T") ?? []
  const date = dateComp.length > 0 ? dateComp[0] : ""
  const content = parse(post?.content.rendered ?? "")
  const featured_url = post?.featured_url ?? ""
  const featured = featured_url == false ? "" : featured_url
  const tags = post?.tags ?? []
  
  const toGmap = () => {
    const link = post?.acf?.gmap_link ?? ""
    window.open(link)
  }

  useEffect(() => {
    fetchPosts()
  }, [post])

  return (
    <div className="is-content-post" style={{
      paddingTop: "-100px"
    }}>
      { post && <PostMap post={post} /> }
      {
        isSp ? <Col align="start" children={[
          <Spacer height="40px" />,
          featured != "" && <img className="is-img" src={featured} alt='featured image' />,
          <Spacer height="9px" />,
          <p className="is-post-date">{date}</p>,
          <Spacer height="9px" />,
          <button className="is-gmap-button-borderless" onClick={toGmap}>Googleマップで見る</button>,
          <Spacer height="17px" />,
          <h1 className="is-post-title">{title}</h1>,
        ]} />
        : <Col align="start" children={[
          <Spacer height="76px" />,
          <h1 className="is-post-title">{title}</h1>,
          <Spacer height="39px" />,
          <Row align="end" spacing="22px" style={{
            width: "100%"
          }} children={[
            <button className="is-gmap-button" style={{
              width: "250px",
              height: "50px"
            }} onClick={toGmap}>Googleマップで見る</button>,
            <p className="is-post-date">{date}</p>,
            <Spacer />
          ]} />,
          <Spacer height="30px" />,
          featured != "" && <img className="is-img" src={featured} alt='featured image' />,
          <Spacer height="53px" />,
        ]} />
      }
      
      <div className="is-post-content">{content}</div>
      <Spacer height="66px" />
      <div style={{
        display: "flex",
        flexFlow: "row",
        flexWrap: "wrap",
        gap: "10px",
        rowGap: "5px",
      }}>
        {
          tags && tags.map((tag) => {
            return <p className="is-post-tags">#{tag}</p>
          })
        }
      </div>
      <Spacer height="67px" />
      <Col align="center" style={{
        width: "100%"
      }} children={[
        // <Pagination pages={[1,2,3,4]} current={1} onClick={(page) => {
        //   console.log(page + "clicked.")
        // }}/>,
        // <Spacer height="56px" />,
        <button className="is-gmap-button" style={{
          width: isSp ? "195px" : "435px", height: isSp ? "38px" : "79px"
        }} onClick={toGmap}>{isSp ? "Googleマップで見る" : "Googleマップでこのエッセイの場所を見る"}</button>
      ]} />
    </div>
  )
}