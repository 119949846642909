import React, { useState } from "react";
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { Row, Col, Box, Spacer } from '../components/FlexBox'
import './Contact.css'

function Checkbox({value, current, onClick}) {
  return (
    <Row spacing='12px' children={[
      <div className={current != value ? "is-contact-checkbox" : "is-contact-checkbox-active" } onClick={() => { onClick(value) }} />,
      <p className="is-contact-select-item">{value}</p>,
      <Spacer />
    ]} />
  )
}

export default function Contact() {
  const isSp = useMediaQuery(mediaQuery.sp)
  const [company, setCompany] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [summary, setSummary] = useState("")
  const [detail, setDetail] = useState("")
  const [isAgreed, setIsAgreed] = useState(false)

  return (
    <div>
      <div className="is-content-list">
        <Box children={[
          <p className="is-title-above">CONTACT</p>
        ]} />
        <h1 className="is-title">各種お問い合わせについて</h1>
      </div>
      <form className="is-content-form">
        <Spacer height={isSp ? '27px' : '59px'} />
        <p className="is-contact-desc">
          <strong>イセシマップ</strong>へのお問い合わせはこちらになります<br />
          取材ご依頼をお考えの方はこちらからご連絡ください。<br />
          ４〜５日後にご連絡させていただきます。<br />
        </p>
        <Spacer height={isSp ? '71px' : '105px'} />
        <input type="text" className="is-contact-input" placeholder="貴社名" value={company} onChange={(event) => { setCompany(event.target.value) }} />
        <Spacer height='22px' />
        <input type="text" className="is-contact-input" placeholder="※御担当者名" value={name} onChange={(event) => { setName(event.target.value) }} />
        <Spacer height='22px' />
        <input type="email" className="is-contact-input" placeholder="※メールアドレス" value={email} onChange={(event) => { setEmail(event.target.value) }} />
        <Spacer height={isSp ? '30px' : '52px'} />
        {/* <p className="is-contact-select-desc">※ご依頼内容を選択してください</p>
        <Spacer height={isSp ? '13px' : '24px'} />
        <Checkbox value="広告掲載について" current={summary} onClick={setSummary} />
        <Spacer height={isSp ? '13px' : '9px'} />
        <Checkbox value="取材依頼について" current={summary} onClick={setSummary} />
        <Spacer height={isSp ? '13px' : '9px'} />
        <Checkbox value="ライター志望について" current={summary} onClick={setSummary} />
        <Spacer height={isSp ? '13px' : '9px'} />
        <Checkbox value="その他" current={summary} onClick={setSummary} />
        <Spacer height={isSp ? '27px' : '42px'} /> */}
        <textarea className="is-contact-textarea" placeholder="※お問い合わせ内容" value={detail} onChange={(event) => { setDetail(event.target.value) }} />
        <Spacer height={isSp ? '30px' : '68px'} />
        <input type='submit' className="is-contact-send" value='送信する' />
        <Spacer height='40px' />
        <Row spacing='15px' children={[
          <Box children={[
            <div className="is-contact-checkbox" />,
            isAgreed && <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16" fill="none" viewBox="0 0 25 16">
              <path stroke="#000" d="m1 4.5 10 10L24.5 1"/>
            </svg>
          ]} onClick={() => { setIsAgreed(!isAgreed) }} />,
          <p className="is-contact-agreement">プライバシポリシーに同意</p>,
          <Spacer />
        ]} />
      </form>
    </div>
  );
}