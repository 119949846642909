import React, { useEffect, useState } from "react";
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { Row, Col, Box, Spacer } from './FlexBox'
import { useNavigate } from "react-router-dom"
import Card from "./Card"
import "./Around.css"
import { AroundMap } from "./Maps";

export default function Around() {
  const isSp = useMediaQuery(mediaQuery.sp)
  const navigate = useNavigate()
  const [around, setAround] = useState([])

  const toHome = () => {
    navigate("/")
  }

  return (
    <div className="is-content-list">
      <h1 className="is-title">その他の周辺エッセイ</h1>
      <Spacer height='39px' />
      <Col className="is-around" style={{width: "100%"}} children={[
        <AroundMap onChangeAround={setAround} />,
        <Spacer height={isSp ? '27px' : '62px'} />,
        isSp ? (
          <div className="is-scroll-horizontal">
            <Row className="is-scroll-content" align='start' spacing='5.6vw' children={[
              around.map((post) => {
                return <Card post={post} style={{width: '56vw'}} />
              })
            ]} />
          </div>
        ) : (
          <Row align="start" spacing='2.2vw' style={{width: "100%"}} children={[
            around.map((post) => {
              return <Card post={post} />
            }),
          ]} />
        ),
        <Spacer height={isSp ? '22px' : '62px'} />,
        !isSp && <Row style={{width: '100%'}} children={[
          <button className="is-button" onClick={toHome}>HOME</button>
        ]} />
      ]} />
    </div>
  );
}