import React, { useState, useRef } from "react"
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { Row, Col, Box, Spacer } from './FlexBox'
import { useNavigate } from "react-router-dom"

function Line() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="18" viewBox="0 0 2 18" fill="none"><path d="M1 1V17" stroke="#008092" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/></svg>
  );
}

export default function SearchBar(props) {
  const isSp = useMediaQuery(mediaQuery.sp)
  const [words, setWords] = useState("")
  const [isFocus, setIsFocus] = useState(false)
  const ref = useRef()
  const navigate = useNavigate()

  const focus = () => {
    setIsFocus(true)
  }

  const unfocus = () => {
    setIsFocus(false)
  }

  const submittable = (e) => {
    if(e.key == "Enter") {
      navigate(`/search?words=${words}`)
    }
  }
  
  return (
    <Box style={{
      width: '100%',
      maxWidth: isSp ? 'none' : '304px',
      height: isSp ? '32.8px' : '40px',    
    }} children={[
      <div style={{
        width: '100%',
        height: '100%',
        background: '#FFF',
        border: '2px solid #008092',
        borderRadius: '6.5px',
      }} />,
      <Row spacing='12px' style={{
        width: '100%',
        padding: '0 14px',
      }} children={[
        <img src="/images/search_icon.svg" alt="search enter" style={{
          cursor: 'pointer',
        }} onClick={() => {
          navigate('/search?words='+words)
        }}/>,
        <Line />,
        <Box horizontalAlign='start' style={{
          width: '100%',
          height: '100%'
        }} children={[
          (!isFocus && words.length === 0) && <p onClick={() => {
            ref.current.focus()
          }} style={{
            textAlign: 'start',
            color: 'rgba(0, 128, 146, 0.50)',
            fontFamily: 'poppins',
            fontSize: '14px',
            fontWeight: 700,
            marginTop: '4px',
          }}>SEARCH</p>,
          <input ref={ref} onFocus={focus} onBlur={unfocus} onKeyDown={submittable} style={{
            width: '100%',
            height: '40px',
            border: 'none',
            background: 'transparent',
            fontSize: '14px',
            fontWeight: 700,
          }} value={words} onChange={(event) => {
            setWords(event.target.value)
          }} />,
        ]} />
      ]} />,
    ]} />
  )
}