import React from 'react';
import ReactDOM from 'react-dom/client';
import { mediaQuery, useMediaQuery } from './useMediaQuery';
import { createBrowserRouter, Outlet, RouterProvider, ScrollRestoration, useLocation } from "react-router-dom";
import { Spacer } from './components/FlexBox';
import './index.css';
import Home from './routes/Home';
import Post from './routes/Post';
import Category from './routes/Category';
import Search from './routes/Search';
import About from './routes/About';
import Contact from './routes/Contact';
import PrivacyPolicy from './routes/PrivacyPolicy';
import Header from './components/Header';
import Around from './components/Around';
import Footer from './components/Footer';
import Map from './components/Map';

function Root() {
  const isSp = useMediaQuery(mediaQuery.sp)
  const location = useLocation()
  const path = location.pathname

  return (
    <div>
      {
        path === "/" && !isSp && <Map />
      }
      <Header />
      {
        path === "/" && isSp && <Map />
      }
      <div className="is-content">
        <Outlet />
        { 
          path !== "/" &&
          path !== "/contact" &&
          path !== "/privacy-policy" && <Spacer height={isSp ? '169px' : '400px'} />
        }{
          path !== "/" &&
          path !== "/contact" &&
          path !== "/privacy-policy" && <Around />
        }{ 
          path !== "/privacy-policy" && <Spacer height={isSp ? '174px' : '285px'} /> 
        }
      </div>
      <ScrollRestoration />
      <Footer />
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "",
        element: <Home />
      },
      {
        path: "essay/:post",
        element: <Post />
      },
      {
        path: "category/:category",
        element: <Category />
      },
      {
        path: "search",
        element: <Search />
      },
      {
        path: "about",
        element: <About />
      },
      {
        path: "contact",
        element: <Contact />
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);