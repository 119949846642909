import React, { useState } from "react"
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { useNavigate } from "react-router-dom"
import 'leaflet/dist/leaflet.css'
import { Row, Col, Box, Spacer } from '../components/FlexBox'
import "./Map.css"
import { useAnimate } from 'framer-motion';
import { MainMap } from "./Maps"

function Arrow({isModal, onClick, onTouchStart, onTouchMove, onTouchEnd}) {
  const isSp = useMediaQuery(mediaQuery.sp)

  return (
    <svg className="is-map-sheet-arrow" width="48" height="97" viewBox="0 0 48 97" fill="none" xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}>
      <circle cx="48.5" cy="48.5" r="48" fill="white" stroke="#ED6C10"></circle>
      {
        isModal ? 
        <path d="M35.4331 47.3453C35.7585 47.7211 35.7585 48.2789 35.4331 48.6547L23.5059 62.4269C22.8998 63.1268 21.75 62.6981 21.75 61.7723L21.75 34.2277C21.75 33.3019 22.8998 32.8732 23.5059 33.5731L35.4331 47.3453Z" fill="#ED6C10"></path>
        : 
        <path d="M17.5669 48.6547C17.2415 48.2789 17.2415 47.7211 17.5669 47.3453L29.4941 33.5731C30.1002 32.8732 31.25 33.3019 31.25 34.2277L31.25 61.7723C31.25 62.6981 30.1002 63.1268 29.4941 62.4269L17.5669 48.6547Z" fill="#ED6C10"></path>
      }
    </svg>
  )
}

function AroundCard({post, style}) {
  const isSp = useMediaQuery(mediaQuery.sp)
  const navigate = useNavigate()

  const title = post?.title.rendered ?? ""
  const excerpt = post?.excerpt?.rendered.slice(3).slice(0, -5) ?? ""
  const featuredUrl = post?.featured_url ?? "/images/noimage.jpeg"
  const featured = featuredUrl == false ? "/images/noimage.jpeg" : featuredUrl
  const color = post?.category_info?.color ?? "#666"

  const toEssay = () => {
    const slug = post?.slug
    slug && navigate(`/essay/${slug}`)
  }

  return (
    isSp ? (
      <Row align="start" spacing="6px" style={style} children={[
        <Col align="start" spacing="9px" children={[
          <h3 className="is-card-title nowrap around clickable" onClick={toEssay}>{title}</h3>,
          <p className="is-card-desc around">{excerpt}</p>
        ]} />,
        <img className="is-img clickable" src={featured} alt={title} style={{
          width: "103px",
          border: `1px solid ${color}`
        }} onClick={toEssay} />
      ]} />
    ) : (
      <Col align="start" style={style} children={[
        <img className="is-img clickable" src={featured} alt={title} style={{
          border: `1px solid ${color}`
        }} onClick={toEssay} />,
        <Spacer height="8px" />,
        <h3 className="is-card-title nowrap around clickable" onClick={toEssay}>{title}</h3>,
        <Spacer height="5px" />,
        <p className="is-card-desc around">{excerpt}</p>
      ]} />
    )
  )
}


function Around({posts}) {
  const isSp = useMediaQuery(mediaQuery.sp)

  return <div className="is-around">
    {
      isSp ? (
        <Col children={[
          <Spacer height="5px" />,
          <h2 className="is-map-sheet-title">この周辺に関してのエッセイ</h2>,
          <Spacer height="26px" />,
          <Col spacing="15px" style={{margin: "0 3.125%"}} children={[
            posts.map((post, index) => {
              return <Col spacing="15px" children={[
                index > 0 && <div className="is-dashed" />,
                <AroundCard post={post} style={{width: "100%"}} />
              ]} />
            })
          ]} />
        ]} />
      ) : (
        <Col children={[
          <Spacer height="20px" />,
          <h2 className="is-map-sheet-title">この周辺に関してのエッセイ</h2>,
          <Spacer height="20px" />,
          <Col spacing="15px" style={{padding: "0 15%"}} children={[
            posts.map((post) => {
              return <AroundCard post={post} style={{width: "100%"}} />
            })
          ]} />
        ]} />
      )
    }
  </div>
}

function Excerpt({post, isOpen}) {
  const isSp = useMediaQuery(mediaQuery.sp)
  const navigate = useNavigate()

  const title = post?.title.rendered ?? ""
  const dateComp = post?.date.split("T") ?? []
  const date = dateComp.length > 0 ? dateComp[0] : ""
  const excerpt = post?.excerpt?.rendered.slice(3).slice(0, -5) ?? ""
  const featuredUrl = post?.featured_url ?? "/images/noimage.jpeg"
  const featured = featuredUrl == false ? "/images/noimage.jpeg" : featuredUrl

  const toGmap = () => {
    const link = post?.acf?.gmap_link ?? ""
    window.open(link, '_blank')
  }

  const toEssay = () => {
    const slug = post?.slug
    slug && navigate(`/essay/${slug}`)
  }

  return <div className="is-excerpt">
    {
      isSp ? (
        <Col style={{margin: "0 3.125%", width: "304px"}} align="center" children={[
          !isOpen && <>
            <Spacer height="5px" />
            <h2 className="is-map-sheet-title">このPinに関してのエッセイ</h2>
            <Spacer height="20px" />
          </>,
          <Col align="start" children={[
            <img className="is-img" src={featured} alt={title}/>,
            <Spacer height="8px" />,
            <p className="is-excerpt-date ">{date}</p>,
            <Spacer height="2px" />,
            <h3 className="is-excerpt-title">{title}</h3>,
            <Spacer height="1px" />,
            <p className="is-excerpt-desc">{excerpt}</p>,
            <Spacer height="15px" />,
          ]} />,
          <Row style={{width: "100%"}} spacing="35px" children={[
            <button className="is-gmap-button-mini" onClick={toGmap} />,
            <button className="is-button" onClick={toEssay}>More...</button>,
          ]} />
        ]} />
      ) : (
        <Col style={{padding: "0 10%"}} align="start" children={[
          <Spacer height="20px" />,
          <h2 className="is-map-sheet-title">このPinに関してのエッセイ</h2>,
          <Spacer height="20px" />,
          <img className="is-img" src={featured} alt={title} />,
          <Spacer height="5px" />,
          <p className="is-excerpt-date">{date}</p>,
          <h3 className="is-excerpt-title">{title}</h3>,
          <Spacer height="20px" />,
          <button className="is-gmap-button" style={{width: "100%", height: "44px"}} onClick={toGmap}>Googleマップで見る</button>,
          <Spacer height="20px" />,
          <p className="is-excerpt-desc">{excerpt}</p>,
          <Spacer height="15px" />,
          <Row style={{width: "100%"}} children={[
            <button className="is-button" onClick={toEssay}>More...</button>,
          ]} />,
          <Spacer height="20px" />
        ]} />
      )
    }
  </div>
}

export default function Map() {
  const isSp = useMediaQuery(mediaQuery.sp)

  /** 周辺エッセイまたは選択したPinのエッセイ */
  const [around, setAround] = useState([])
  const [selection, setSelection] = useState(undefined)

  /** シートの操作 */
  const [isOpen, setIsOpen] = useState(true)
  const [isDragging, setIsDragging] = useState(false)
  const [startY, setStartY] = useState(0)
  const [dragY, setDragY] = useState(0)
  const [spScope, spAnimate] = useAnimate()
  const [pcScope, pcAnimate] = useAnimate()

  const openSheet = () => {
    setIsOpen(true)
    if(isSp) {
      spAnimate(spScope.current, { transform: "translateY(0px)" })
    } else {
      pcAnimate(pcScope.current, { transform: "translateX(0px)" })
    }
  }

  const closeSheet = () => {
    setIsOpen(false)
    if(isSp) {
      spAnimate(spScope.current, { transform: "translateY(330px)" })
    } else {
      pcAnimate(pcScope.current, { transform: "translateX(280px)" })
    }
  }

  const startDrag = (from) => {
    if(isSp) {
      setStartY(from)
      setIsDragging(true)
    }
  }

  const changeDrag = (to) => {
    if(isSp && isDragging) {
      const changed = to - startY
      if(isOpen) {
        if(changed <= 330 && changed >= 0) {
          setDragY(changed)
          spAnimate(spScope.current, { transform: `translateY(${changed}px)` }, { duration: 0, delay: 0 })
        }
      } else {
        if(changed <= 0 && changed >= -330) {
          setDragY(changed)
          spAnimate(spScope.current, { transform: `translateY(${330+changed}px)` }, { duration: 0, delay: 0 })
        }
      }
    }
  }

  const endDrag = () => {
    if(isSp && isDragging) {
      if(isOpen) {
        dragY > 100 ? closeSheet() : openSheet()
      } else {
        dragY < -100 ? openSheet() : closeSheet()
      }
      setDragY(0)
      setIsDragging(false)
    }
  }
  
  return (
    <div className="is-map">
      <Box verticalAlign="start" horizontalAlign="start" children={[
        <Box verticalAlign={isSp ? "end" : "start"} horizontalAlign={!isSp ? "end" : "start"} children={[
          <Col children={[
            <MainMap onChangeSelection={(post) => {
              setSelection(post)
              openSheet()
            }} onChangeAround={(around) => {
              setAround(around)
            }} />,
            !isSp && <Spacer height="80px" />
          ]} />,
          isSp ? (
            <div className="is-map-sheet" ref={spScope} style={{ transform: "translateY(0px)" }} >
              <Col children={[
                <Box className="is-map-sheet-draggable" children={[
                  <div className="is-map-sheet-bar" />,
                  <div className="is-map-sheet-draggable" onTouchStart={(e) => {
                    startDrag(e.touches[0].pageY)
                  }} onTouchMove={(e) => {
                    changeDrag(e.touches[0].pageY)
                  }} onTouchEnd={(e) => {
                    endDrag()
                  }} style={{touchAction: "none"}}/>
                ]} />,
                selection == undefined ? (
                  <Around posts={around} />
                ) : (
                  <Excerpt post={selection} isOpen={isOpen} />
                ),
                <Spacer />
              ]} />
            </div>
          ) : (
            <div ref={pcScope} style={{ transform: "translateX(0px)", zIndex: 3 }} >
              <Row children={[
                <Arrow onClick={() => {
                  isOpen ? closeSheet() : openSheet()
                }} isModal={isOpen} />,
                <Col className="is-map-sheet" children={
                  selection == undefined ? [
                    <Around posts={around} />,
                    <Spacer />
                  ] : [
                    <Excerpt post={selection} />,
                    <Spacer />
                  ]
                } />
              ]} />
            </div>
          )
        ]} />,
        !isSp && <img className="is-map-logo" src="/images/title_logo.svg" alt="イセシマップ" />
      ]} />
    </div>
  )
}