import React, { useState } from "react";
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { Row, Col, Box, Spacer } from '../components/FlexBox'
import './About.css'

function Arrow() {
  const isSp = useMediaQuery(mediaQuery.sp)

  return (
    isSp ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="34" viewBox="0 0 18 34" fill="none">
      <path d="M9 34L17.6603 19L0.339745 19L9 34ZM7.5 -6.55671e-08L7.5 20.5L10.5 20.5L10.5 6.55671e-08L7.5 -6.55671e-08Z" fill="#ED6C10"/>
    </svg>
    : <svg xmlns="http://www.w3.org/2000/svg" width="61" height="30" viewBox="0 0 61 30" fill="none">
      <path d="M61 15L36 0.566243V29.4338L61 15ZM0 17.5H38.5V12.5H0V17.5Z" fill="#ED6C10"/>
    </svg>
  )
}

function Line() {
  const isSp = useMediaQuery(mediaQuery.sp)

  return (
    isSp ? <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 196 2" fill="none">
      <path d="M0 1L196 1.00002" stroke="#6E6565" strokeDasharray="2 2"/>
    </svg>
    : <svg xmlns="http://www.w3.org/2000/svg" width='100%' viewBox="0 0 272 2" fill="none">
      <path d="M0 1L272 0.999976" stroke="#6E6565" strokeDasharray="2 2"/>
    </svg>
  )
}

function OwnerImage() {
  const isSp = useMediaQuery(mediaQuery.sp)

  return (
    <Col spacing='13px' style={{
      width: isSp ? '100%' : '23.6vw'
    }} children={[
      <Row spacing='10px' style={{
        width: '100%'
      }} children={[
        <p className="is-about-owner">作ってる人</p>,
        <Line />,
      ]} />,
      <img className="is-about-owner-icon" src='/images/owner_icon.png' alt='owner icon' />,
    ]} />
  )
}

function OwnerName() {
  return (
    <p className="is-about-owner-name">オヅ ソウタロウ</p>
  )
}

function OwnerSNS() {
  return (
    <Row spacing='25px' children={[
      <img className="is-sns-icon" src='/images/instagram_icon.svg' alt='instagram' onClick={() => {
        window.open("https://www.instagram.com/oz.illustrator_design/?hl=ja")
      }} />,
      <img className="is-sns-icon" src='/images/x_icon.svg' alt='x' onClick={() => {
        window.open("https://twitter.com/ozyozozyzo")
      }} />,
      <img className="is-sns-icon" src='/images/note_icon.svg' alt='note' onClick={() => {
        window.open("https://note.com/cronnaua")
      }} />,
    ]} />
  )
}

function OwnerDesc() {
  return (
    <p style={{
      width: '97%',
      paddingLeft: '5px',
    }} className="is-about-owner-desc">
      1998年生まれ、伊勢志摩と東京の２拠点でデザイナーをしています。<br />
      伊勢志摩で100年以上伊勢志摩の幼稚園や学校に文房具を卸す会社のを5代目私は第二創業で企業向けのデザイン制作やブランディングを行っています。
    </p>
  )
}

export default function About() {
  const isSp = useMediaQuery(mediaQuery.sp)

  return (
    <div>
      <div className="is-content-list">
        <Box children={[
          <p className="is-title-above">ABOUT</p>
        ]} />
        <h1 className="is-title"><strong>イセシマップ</strong>について</h1>
      </div>
      <div className="is-content-post">
        <Spacer height={isSp ? '41px' : '91px'} />
        {
          isSp ? <Col spacing='15px' children={[
            <div className="is-about-large">何でもない場所</div>,
            <Arrow />,
            <div className="is-about-large">目的地へ</div>,
          ]} />
          : <Row children={[
            <div className="is-about-large">何でもない場所</div>,
            <Spacer />,
            <Arrow />,
            <Spacer />,
            <div className="is-about-large">目的地へ</div>,
          ]} />
        }
        <Spacer height={isSp ? '46px' : '91px'} />
        <p className="is-about-desc">
          伊勢志摩って地域は『伊勢神宮』があるからか、結構日本の歴史や文化の根源だったりするエピソードを発見をすることがあるんですよ。<br />
          例えば、<br />
          <br />
          皆さんが夏祭りなどで踊る音頭ってほとんどが『伊勢音頭』をベースにして作られているんですよ。なぜなら江戸時代三代遊郭の一つ『古市』があったからで...<br />
          <br />
          小学生の頃通学路にあった謎の大きな『石』、実はこれ名古屋から伊勢まで迷わないための道標で一定の距離においてあるんだとか...<br />
          <br />
          とまあ、ピンポイントなキーワードで調べないとなかなかインターネットでも出てこない伊勢志摩から紐解く『歴史・食べ物・人物』などのエピソードエッセイを実際の伊勢志摩の縁のある場所とともに楽しんでもらうメディアサイトです。<br />
          <br />
          エピソードを知っているだけで、<strong>『何でもない場所』</strong>があなたにだけは<strong>魅力的な目的地』</strong>になるかもしれませんね。
        </p>
      </div>
      <div className="is-content-info">
        <Spacer height={isSp ? '98px' : '166px'} />
        {
          isSp ? <Col align='start' children={[
            <OwnerImage />,
            <Spacer height='20px' />,
            <OwnerName />,
            <Spacer height='20px' />,
            <OwnerSNS />,
            <Spacer height='35px' />,
            <OwnerDesc />,
            <Spacer height='21px' />,
            <Box style={{
              width: '100%'
            }} children={[
              <button className="is-site-button" onClick={() => {
                window.open("https://sahanzi.com/")
              }}>サイトはこちら</button>
            ]} />
          ]} />
          : <Row spacing='5.5vw' children={[
            <OwnerImage />,
            <Col align='start' style={{
              width: '36.4vw'
            }} children={[
              <Row style={{
                width: '100%'
              }} children={[
                <OwnerName />,
                <Spacer />,
                <OwnerSNS />,
              ]} />,
              <Spacer height='21px' />,
              <OwnerDesc />,
              <Spacer height='50px' />,
              <button className="is-site-button" onClick={() => {
                window.open("https://sahanzi.com/")
              }}>サイトはこちら</button>
            ]} />
          ]} />
        }
      </div>
    </div>
  );
}